@charset "UTF-8";
/*--------------------------------------------------------------
* フォントの読み込み
----------------------------------------------------------------*/
/*--------------------------------------------------------------
* 使用font の変数
----------------------------------------------------------------*/
/*--------------------------------------------------------------
* フォントサイズ の変数
----------------------------------------------------------------*/
/* pc
_______________________________________________________________________________________________________*/
/* sp
_______________________________________________________________________________________________________*/
/* =========================================================================================================
	共通定義する値
========================================================================================================= */
/* コンテンツ幅
_______________________________________________________________________________________________________*/
/* 使用色
_______________________________________________________________________________________________________*/
/* イージング
_______________________________________________________________________________________________________*/
/*
 * Easing function
 * Original：http://easings.net/
 */
/*--------------------------------------------------------------
* globalフォルダの_index.scss
----------------------------------------------------------------*/
.l-mv {
  position: relative;
  z-index: 0;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .l-mv__bk {
    height: auto;
  }
}
.l-mv__bk-img {
  display: block;
}
.l-mv__bk-img img {
  height: 100svh;
  object-fit: cover;
  object-position: center;
}
@media screen and (min-width: 768px) {
  .l-mv__bk-img img {
    height: auto;
  }
}
.l-mv__link-btn {
  position: absolute;
  position: fixed;
  z-index: 10;
  right: 25px;
  bottom: 30px;
}
@media screen and (min-width: 768px) {
  .l-mv__link-btn {
    right: 50px;
  }
}

.l-message {
  position: relative;
  z-index: 0;
  margin-top: 63px;
}
@media screen and (min-width: 768px) {
  .l-message {
    margin-top: clamp(200px, 17.8571428571vw, 250px);
    padding-top: clamp(50px, 7.6428571429vw, 107px);
  }
}
.l-message__bk {
  position: relative;
}
.l-message__bk-title {
  max-width: 106.5px;
}
@media screen and (min-width: 768px) {
  .l-message__bk-title {
    position: absolute;
    top: clamp(-106px, -7.3103448276vw, -50px);
    left: 8%;
    right: calc(50% - 50vw);
    max-width: 1276px;
  }
}
.l-message__bk-title-img {
  display: none;
}
@media screen and (min-width: 768px) {
  .l-message__bk-title-img {
    display: block;
  }
}
.l-message__bk-inner {
  position: absolute;
  right: calc(50% - 50vw);
  z-index: -1;
}
.l-message__bk-img {
  display: block;
  height: 100%;
}
.l-message__bk-img img {
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1462/878;
  height: 100%;
}

.l-service {
  position: relative;
  z-index: 5;
}
@media screen and (min-width: 768px) {
  .l-service {
    padding-bottom: 75px;
  }
}
@media screen and (min-width: 1025px) {
  .l-service {
    padding-bottom: 450px;
  }
}
.l-service__title {
  display: block;
  writing-mode: vertical-lr;
  position: absolute;
  z-index: 2;
  top: -47px;
  left: 0;
  white-space: nowrap;
  font-family: "Oswald", sans-serif;
  font-size: 11.25rem;
  font-size: clamp(5.625rem, 12.8571428571vw, 11.25rem);
  letter-spacing: 0.004em;
  line-height: 0.9;
  overflow: hidden;
  color: rgba(26, 19, 17, 0.06);
}
@media screen and (min-width: 768px) {
  .l-service__title {
    top: clamp(-95px, -6.7857142857vw, -40px);
    right: 0;
    left: auto;
  }
}
@media screen and (min-width: 1025px) {
  .l-service__title {
    right: clamp(-17px, -1.2142857143vw, 10px);
  }
}
.l-service__title span {
  color: rgba(255, 255, 255, 0.3);
  display: inline-block;
}
.l-service__bk {
  position: absolute;
  inset: 0;
  height: 100%;
  overflow: hidden;
}
.l-service__bk01 {
  width: clamp(61.5px, 7.6875vw, 123px);
  aspect-ratio: 1/1;
  background-color: rgba(218, 0, 62, 0.21);
  position: absolute;
  top: 30%;
  left: 12%;
}
@media screen and (min-width: 768px) {
  .l-service__bk01 {
    top: 34%;
    left: 18%;
  }
}
.l-service__bk02 {
  width: clamp(93.5px, 11.6875vw, 187px);
  aspect-ratio: 1/1;
  background-color: rgba(0, 167, 235, 0.08);
  position: absolute;
  top: 44%;
  left: -13%;
}
@media screen and (min-width: 768px) {
  .l-service__bk02 {
    top: 54%;
    left: 4%;
  }
}
.l-service__bk03 {
  width: clamp(39px, 4.875vw, 78px);
  aspect-ratio: 1/1;
  background-color: rgba(0, 50, 136, 0.14);
  position: absolute;
  top: 66%;
  left: 8%;
}
@media screen and (min-width: 768px) {
  .l-service__bk03 {
    top: 73.5%;
    left: 38%;
  }
}
.l-service__bk04 {
  width: clamp(75px, 9.375vw, 150px);
  aspect-ratio: 1/1;
  background-color: rgba(0, 50, 136, 0.14);
  position: absolute;
  top: 30%;
  left: 74%;
}
@media screen and (min-width: 768px) {
  .l-service__bk04 {
    top: 30%;
    left: 74%;
  }
}
.l-service__bk05 {
  width: clamp(39.5px, 4.9375vw, 79px);
  aspect-ratio: 1/1;
  background-color: rgba(218, 0, 62, 0.21);
  position: absolute;
  top: 47%;
  left: 92%;
}
@media screen and (min-width: 768px) {
  .l-service__bk05 {
    top: 53%;
    left: 87.5%;
  }
}
.l-service__bk06 {
  width: clamp(67.5px, 8.4375vw, 135px);
  aspect-ratio: 1/1;
  background-color: rgba(0, 167, 235, 0.08);
  position: absolute;
  top: 69%;
  left: 80%;
}
@media screen and (min-width: 768px) {
  .l-service__bk06 {
    top: 69%;
    left: 65%;
  }
}

.l-company {
  position: relative;
  padding-bottom: 30px;
  margin-top: 72px;
}
@media screen and (min-width: 768px) {
  .l-company {
    margin-top: 0;
  }
}
.l-company__bk {
  width: 100%;
  left: 0;
  top: 0;
}
@media screen and (min-width: 768px) {
  .l-company__bk {
    position: absolute;
  }
}
.l-company__bk01 {
  position: relative;
}
.l-company__bk01-img, .l-company__bk02-img {
  width: 56.2666666667%;
}
@media screen and (min-width: 768px) {
  .l-company__bk01-img, .l-company__bk02-img {
    width: 26.375%;
  }
}
.l-company__bk01-img {
  z-index: 1;
}
.l-company__bk02-img {
  z-index: -1;
  position: absolute;
  top: 37%;
  left: 20%;
  opacity: 5%;
}
@media screen and (min-width: 768px) {
  .l-company__bk02-img {
    left: 9%;
  }
}

/*--------------------------------------------------------------
* layoutフォルダの_index.scss
----------------------------------------------------------------*/
/*!
component > Loading
------------------------------
*/
.is_loadding .c_loading {
  pointer-events: all;
  opacity: 1;
}

.c_loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: #003288;
  opacity: 0;
}
.c_loading__content {
  position: relative;
  -webkit-transform: translate(-15px, -15px);
  transform: translate(-15px, -15px);
}
.c_loading__item {
  position: absolute;
  top: 4px;
  left: -7px;
  width: 12px;
  height: 12px;
  margin: 2px;
  margin-left: 15px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.c_loading__item:first-child {
  -webkit-animation: ball-zig 0.7s 0s infinite linear;
  animation: ball-zig 0.7s 0s infinite linear;
}
.c_loading__item:last-child {
  -webkit-animation: ball-zag 0.7s 0s infinite linear;
  animation: ball-zag 0.7s 0s infinite linear;
}

@-webkit-keyframes ball-zig {
  33% {
    -webkit-transform: translate(-15px, -30px);
    transform: translate(-15px, -30px);
  }
  66% {
    -webkit-transform: translate(15px, -30px);
    transform: translate(15px, -30px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes ball-zig {
  33% {
    -webkit-transform: translate(-15px, -30px);
    transform: translate(-15px, -30px);
  }
  66% {
    -webkit-transform: translate(15px, -30px);
    transform: translate(15px, -30px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@-webkit-keyframes ball-zag {
  33% {
    -webkit-transform: translate(15px, 30px);
    transform: translate(15px, 30px);
  }
  66% {
    -webkit-transform: translate(-15px, 30px);
    transform: translate(-15px, 30px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes ball-zag {
  33% {
    -webkit-transform: translate(15px, 30px);
    transform: translate(15px, 30px);
  }
  66% {
    -webkit-transform: translate(-15px, 30px);
    transform: translate(-15px, 30px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
/*!
component > cookiewrap
------------------------------
*/
#cookiewrap {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  box-sizing: border-box;
  display: none;
  width: 100%;
  padding: 20px;
  color: #ffffff;
  background: #003288;
}
@media screen and (min-width: 768px) {
  #cookiewrap {
    padding: 40px;
  }
}
#cookiewrap .inner {
  position: relative;
  box-sizing: border-box;
  width: 100%;
}
#cookiewrap .inner .close {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: rem(14);
  line-height: 1em;
  text-align: right;
}
@media screen and (min-width: 768px) {
  #cookiewrap .inner .close {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
}
#cookiewrap .inner .close a {
  display: block;
  padding: 8px;
  border: 1px solid #ffffff;
}
#cookiewrap .inner .txt {
  padding-bottom: 45px;
  font-size: rem(14);
  line-height: 1.8;
}
@media screen and (min-width: 768px) {
  #cookiewrap .inner .txt {
    padding-right: 110px;
    padding-bottom: 0;
  }
}

.c-hamburger-menu {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 25px;
  cursor: pointer;
  transition: all 0.4s;
}
@media screen and (min-width: 768px) {
  .c-hamburger-menu {
    width: 40px;
    height: 30px;
  }
}
.c-hamburger-menu span {
  display: inline-block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #707070;
  transition: all 0.4s;
}
.c-hamburger-menu__line01 {
  top: 0;
}
.c-hamburger-menu__line02 {
  top: 11px;
}
@media screen and (min-width: 768px) {
  .c-hamburger-menu__line02 {
    top: 13.5px;
  }
}
.c-hamburger-menu__line03 {
  bottom: 0px;
}
.is-nav-open .c-hamburger-menu__line01 {
  transform: translateY(11px) rotate(-315deg);
}
@media screen and (min-width: 768px) {
  .is-nav-open .c-hamburger-menu__line01 {
    transform: translateY(13.5px) rotate(-315deg);
  }
}
.is-nav-open .c-hamburger-menu__line02 {
  opacity: 0;
}
.is-nav-open .c-hamburger-menu__line03 {
  transform: translateY(-11px) rotate(315deg);
}
@media screen and (min-width: 768px) {
  .is-nav-open .c-hamburger-menu__line03 {
    transform: translateY(-13.5px) rotate(315deg);
  }
}

.c-content-width01 {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .c-content-width01 {
    max-width: 1404px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

.c-page-top-btn {
  position: fixed;
  right: 20px;
  bottom: 80px;
  z-index: 200;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s ease-in-out;
  transform: translateY(200px);
}
@media screen and (min-width: 768px) {
  .c-page-top-btn {
    right: 50px;
    bottom: 20px;
  }
}
.top .c-page-top-btn {
  bottom: 140px;
}
@media screen and (min-width: 768px) {
  .top .c-page-top-btn {
    bottom: 230px;
  }
}
.c-page-top-btn.is-show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.is-nav-open .c-page-top-btn, .is-lock .c-page-top-btn {
  visibility: hidden;
  opacity: 0;
  transform: translateY(200px);
}
.is-loadding .c-page-top-btn {
  visibility: hidden;
  opacity: 0;
  transform: translateY(200px);
}
.c-page-top-btn__body {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  background-color: #003288;
  transition: 0.3s ease-in;
}
@media screen and (min-width: 768px) {
  .c-page-top-btn__body {
    width: 80px;
    height: 80px;
  }
}
.c-page-top-btn__body::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  content: "";
  background: url("../images/common/icon_arrow-white.svg") no-repeat center;
  background-size: contain;
  transform: translate(-50%, -50%) rotate(90deg);
}
@media screen and (min-width: 768px) {
  .c-page-top-btn__body::before {
    width: 30px;
    height: 30px;
  }
}

.c-modal {
  position: relative;
}
.c-modal__bg {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.94);
  opacity: 0;
  /* 初期状態で非表示 */
  visibility: hidden;
  /* 初期状態で非表示 */
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
.is-modal-active .c-modal__bg {
  opacity: 1;
  /* 非表示を解除 */
  visibility: visible;
  /* 非表示を解除 */
}
.c-modal__inner {
  position: fixed;
  z-index: 20;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  opacity: 0;
  /* 初期状態で非表示 */
  visibility: hidden;
  /* 初期状態で非表示 */
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
.is-modal-active .c-modal__inner {
  opacity: 1;
  /* 非表示を解除 */
  visibility: visible;
  /* 非表示を解除 */
}
@media screen and (min-width: 1025px) {
  .c-modal__inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.c-modal__content {
  max-width: 1380px;
  width: 100%;
}
.c-modal__content-wrpper {
  position: relative;
  width: 100%;
  margin-inline: auto;
  overflow: auto;
  height: 100vh;
  padding: 55px 30px;
}
@media screen and (min-width: 1025px) {
  .c-modal__content-wrpper {
    display: flex;
    align-items: center;
    padding: 0 40px;
  }
}
.c-modal__img-sect {
  position: relative;
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .c-modal__img-sect {
    padding-right: 80px;
  }
}
.c-modal__img {
  max-width: 1017px;
}
.c-modal__card-close {
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: calc(100% + 11px);
}
@media screen and (min-width: 1025px) {
  .c-modal__card-close {
    top: 0;
    bottom: auto;
  }
}
.c-modal__card-closeBtn {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .c-modal__card-closeBtn {
    gap: 12px;
    font-size: 1rem;
  }
}
.c-modal__card-closeBtn span {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .c-modal__card-closeBtn span {
    width: 40px;
    height: 40px;
  }
}
.c-modal__card-closeBtn span::before, .c-modal__card-closeBtn span::after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 1px;
  border-radius: 4px;
  background-color: #707070;
  position: absolute;
  left: calc(50% - 6px);
  top: calc(50% - 1px);
  rotate: 45deg;
}
@media screen and (min-width: 768px) {
  .c-modal__card-closeBtn span::before, .c-modal__card-closeBtn span::after {
    width: 30px;
  }
}
.c-modal__card-closeBtn span::after {
  rotate: -45deg;
}
.c-modal__content-body {
  width: 100%;
  margin-top: 20px;
}
@media screen and (min-width: 1025px) {
  .c-modal__content-body {
    background-color: rgba(255, 255, 255, 0.92);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    max-width: 779px;
    max-width: 779px;
    width: 70%;
    padding-top: 55px;
    padding-bottom: 55px;
    padding-left: 87px;
    padding-right: 40px;
    margin-top: 0;
  }
}
@media screen and (min-width: 1025px) {
  .c-modal__content-body {
    width: 100%;
  }
}
.c-modal__content-body-title {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 7px;
}
@media screen and (min-width: 768px) {
  .c-modal__content-body-title {
    padding-bottom: 20px;
  }
}
.c-modal__content-body-title::after {
  content: "";
  background-color: #707070;
  display: inline-block;
  height: 1px;
  max-width: 100px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media screen and (min-width: 768px) {
  .c-modal__content-body-title::after {
    max-width: 180px;
  }
}
.c-modal__content-body-title-en {
  font-family: "Oswald", sans-serif;
  font-size: 3.75rem;
  font-size: clamp(2.25rem, 3.75vw, 3.75rem);
  letter-spacing: 0.004em;
  line-height: 1.3333333333;
  font-weight: 400;
}
.c-modal__content-body-title-ja {
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 2.25;
  font-size: 0.625rem;
}
@media screen and (min-width: 768px) {
  .c-modal__content-body-title-ja {
    font-size: 1rem;
  }
}
.c-modal__content-body-text-sect {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .c-modal__content-body-text-sect {
    margin-top: 40px;
  }
}
.c-modal__content-body-text-lead {
  font-size: 2rem;
  font-size: clamp(1.125rem, 2vw, 2rem);
  font-weight: 600;
  line-height: 1.6666666667;
}
@media screen and (min-width: 768px) {
  .c-modal__content-body-text-lead {
    line-height: 1.5;
  }
}
.c-modal__content-body-text {
  font-weight: 600;
  letter-spacing: 0.04em;
  line-height: 1.4285714286;
  margin-top: 17px;
}
@media screen and (min-width: 768px) {
  .c-modal__content-body-text {
    font-size: 1rem;
    margin-top: 20px;
    line-height: 2.0625;
  }
}

.c-mv-link-btn {
  background-color: #003288;
  position: relative;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
}
@media screen and (min-width: 768px) {
  .c-mv-link-btn {
    max-width: 180px;
    max-height: 180px;
    width: 12.8571428571vw;
    height: 12.8571428571vw;
  }
}
.c-mv-link-btn::after {
  content: "";
  max-width: 91px;
  max-height: 91px;
  width: 91px;
  height: 91px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 768px) {
  .c-mv-link-btn::after {
    max-width: 168px;
    max-height: 168px;
    width: 12vw;
    height: 12vw;
  }
}
.c-mv-link-btn__img {
  position: relative;
  top: 4px;
}
.c-mv-link-btn__img svg {
  max-width: 28px;
}
@media screen and (min-width: 768px) {
  .c-mv-link-btn__img svg {
    max-width: 57px;
    width: 2456.1403508772vw;
  }
}
.c-mv-link-btn__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -14px;
}
@media screen and (min-width: 768px) {
  .c-mv-link-btn__title {
    top: -8px;
  }
}
.c-mv-link-btn__title-en {
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  letter-spacing: 0.04em;
  font-size: 1.375rem;
}
@media screen and (min-width: 768px) {
  .c-mv-link-btn__title-en {
    font-size: 2.25rem;
    font-size: 2.25rem;
    font-size: clamp(1.375rem, 2.5714285714vw, 2.25rem);
  }
}
.c-mv-link-btn__title-ja {
  font-weight: 600;
  letter-spacing: 0.12em;
  font-size: 0.625rem;
  margin-top: -13px;
}
@media screen and (min-width: 768px) {
  .c-mv-link-btn__title-ja {
    font-size: 0.75rem;
    font-size: 0.75rem;
    font-size: clamp(0.625rem, 0.8571428571vw, 0.75rem);
  }
}

.c-circle-move {
  position: relative;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 1025px) {
  .c-circle-move {
    display: block;
    position: absolute;
    left: clamp(-100px, -6.25vw, 1px);
    overflow: visible;
    margin-left: 0;
    margin-right: 0;
  }
}
.c-circle-move__box {
  position: relative;
  z-index: 2;
  display: inline-block;
}
.c-circle-move__img01 {
  z-index: -1;
  top: 0;
  width: 66.8125vw;
  width: clamp(534.5px, 66.8125vw, 1069px);
  animation: rotate-anime01 20s linear infinite;
}
@media screen and (min-width: 1025px) {
  .c-circle-move__img01 {
    width: clamp(534.5px, 66.8125vw, 1069px);
  }
}
.c-circle-move__img02 {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 52.625vw;
  width: clamp(421px, 52.625vw, 842px);
  translate: -50% -50%;
  animation: rotate-anime02 18s linear infinite;
}
@media screen and (min-width: 1025px) {
  .c-circle-move__img02 {
    width: clamp(421px, 52.625vw, 842px);
  }
}
.c-circle-move__logo-sect {
  position: absolute;
  max-width: 260px;
  width: 46.6666666667vw;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 1025px) {
  .c-circle-move__logo-sect {
    max-width: 300px;
    top: 51%;
    left: 52%;
  }
}
@media screen and (min-width: 1200px) {
  .c-circle-move__logo-sect {
    max-width: 320px;
    top: 52%;
  }
}
@media screen and (min-width: 1400px) {
  .c-circle-move__logo-sect {
    max-width: 348px;
    top: 47%;
  }
}
.c-circle-move__logo-sect-sign {
  padding: 0 7px;
  max-width: 250px;
}
@media screen and (min-width: 1025px) {
  .c-circle-move__logo-sect-sign {
    max-width: none;
  }
}
.c-circle-move__logo-sect-messe {
  font-weight: 600;
  font-size: 1rem;
  font-size: 1rem;
  font-size: clamp(0.5rem, 1.1428571429vw, 1rem);
  line-height: 2.25;
  letter-spacing: 0.04em;
  text-align: center;
}
.c-circle-move__company-logo {
  position: relative;
  width: 10.55125vw;
  min-width: 84.42px;
  max-width: 168.82px;
  margin-top: clamp(17px, 2.5vw, 40px);
}
.c-circle-move__company-logo .dots {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #1a1311;
  position: absolute;
  top: calc(42% - 5px);
  left: calc(42% - 5px);
}
.c-circle-move__company-logo .dots span {
  position: relative;
}
.c-circle-move__company-logo .dots span::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 150px;
  min-height: 80px;
  height: 10vw;
  max-height: 160px;
  background-color: #1a1311;
  position: absolute;
  right: 7px;
  rotate: 18deg;
}
@media screen and (min-width: 768px) {
  .c-circle-move__company-logo .dots span::after {
    height: 10vw;
    max-height: 160px;
  }
}
@media screen and (min-width: 1200px) {
  .c-circle-move__company-logo .dots span::after {
    right: 15px;
  }
}
@media screen and (min-width: 1400px) {
  .c-circle-move__company-logo .dots span::after {
    right: 19px;
  }
}
.c-circle-move__company-logo-text {
  font-weight: 600;
  text-align: center;
  font-size: 1rem;
  font-size: clamp(0.75rem, 1.1428571429vw, 1rem);
  line-height: 1.6666666667;
  margin-top: 40px;
  margin-top: clamp(30px, 2.5vw, 40px);
}

@keyframes rotate-anime01 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate-anime02 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
/******************custom scrollbar*******************/
/*

------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: none;
  touch-action: none;
  /* MSPointer events - direct all pointer events to js */
}

.mCustomScrollbar.mCS_no_scrollbar {
  -ms-touch-action: auto;
  touch-action: auto;
}

.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr;
}

.mCSB_container {
  /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto;
}

/* 
  ------------------------------------------------------------------------------------------------------------------------
  2. VERTICAL SCROLLBAR 
  y-axis
  ------------------------------------------------------------------------------------------------------------------------
  */
.mCSB_inside > .mCSB_container {
  margin-right: 20px;
}

#header .mCSB_inside > .mCSB_container {
  margin-right: 0;
}

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
}

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0;
}

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 20px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
}

.mCSB_outside + .mCSB_scrollTools {
  right: -26px;
}

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0;
}

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px;
}

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
}

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0;
}

.mCSB_scrollTools .mCSB_draggerRail {
  width: 20px;
  height: 100%;
  margin: 0 auto;
}

.mCSB_scrollTools .mCSB_dragger {
  /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 50% !important;
  /* minimum dragger height */
  z-index: 1;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  /* the dragger element */
  position: relative;
  width: 20px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0;
}

/* 
  ------------------------------------------------------------------------------------------------------------------------
  3. HORIZONTAL SCROLLBAR 
  x-axis
  ------------------------------------------------------------------------------------------------------------------------
  */
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px;
}

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%;
}

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 40%;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
}

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px;
}

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 40%;
  margin: 7px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  /* minimum dragger width */
  height: 100%;
  left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0;
}

/* 
  ------------------------------------------------------------------------------------------------------------------------
  4. VERTICAL AND HORIZONTAL SCROLLBARS 
  yx-axis 
  ------------------------------------------------------------------------------------------------------------------------
  */
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px;
}

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px;
}

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0;
}

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0;
}

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px;
}

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0;
}

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}

/* 
  ------------------------------------------------------------------------------------------------------------------------
  5. TRANSITIONS  
  ------------------------------------------------------------------------------------------------------------------------
  */
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -o-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

/* 
  ------------------------------------------------------------------------------------------------------------------------
  6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
  ------------------------------------------------------------------------------------------------------------------------
  */
/* 
      ----------------------------------------
      6.1 THEMES 
      ----------------------------------------
      */
/* default theme ("light") */
.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)";
}

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)";
}

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)";
}

.mCSB_scrollTools .mCSB_draggerRail {
  background: #dcdcdc;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #e6b7ac;
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #883521;
}

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #883521;
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)";
}

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /* 
        sprites locations 
        light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
        dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
        */
}

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /* 
        sprites locations
        light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
        dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
        */
}

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /* 
        sprites locations 
        light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
        dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
        */
}

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /* 
        sprites locations 
        light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
        dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
        */
}

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
}

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)";
}

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools {
  width: 8px;
}

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #c9c9c9;
  width: 10px;
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.5);
  width: 10px;
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.6);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.6);
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 3px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.7);
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px;
}

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px;
}

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0;
}

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px;
}

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px;
}

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px;
}

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
}

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto;
}

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px;
}

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px;
}

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0;
}

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0;
}

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */
}

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px;
}

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px;
}

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px;
}

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px;
}

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=");
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px;
}

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
}

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px;
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px;
}

.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2);
}

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555;
}

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px;
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1);
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}

.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px;
}

.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
}

.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555;
}

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0;
}

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px;
}

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto;
}

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px;
}

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px;
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)";
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)";
}

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)";
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)";
}

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
}

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0;
}

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px;
}

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2);
}

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

/* ---------------------------------------- */
.js-custom-scroll {
  position: relative;
}

.js-custom-scroll .mCS-my-theme.mCSB_scrollTools_horizontal {
  height: 14px !important;
  margin-top: 18px;
  background: #eff1f2;
}
@media screen and (min-width: 768px) {
  .js-custom-scroll .mCS-my-theme.mCSB_scrollTools_horizontal {
    height: 20px !important;
  }
}

.js-custom-scroll .mCSB_dragger {
  width: 300px !important;
}

.js-custom-scroll .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin-left: 0;
  background: #0052a4;
  border-radius: 10px;
}
@media screen and (min-width: 768px) {
  .js-custom-scroll .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    height: 20px;
  }
}

.js-custom-scroll .mCSB_scrollTools .mCSB_draggerRail,
.js-custom-scroll .mCSB_scrollTools .mCSB_dragger {
  height: 14px !important;
  background: transparent;
}

/******************************************************/
.js-custom-scroll .mCS-my-theme.mCSB_scrollTools {
  width: 6px;
  background-color: #e3e3e3;
}

.js-custom-scroll .mCS-my-theme.mCSB_scrollTools .mCSB_dragger {
  height: 44px !important;
}

.js-custom-scroll .mCS-my-theme.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #1a1311;
  width: 6px;
  height: 44px;
  border-radius: 0;
}

.js-custom-scroll .mCS-my-theme.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #e5e5e5;
  width: 6px;
}

/*--------------------------------------------------------------
* componetフォルダの_index.scss
----------------------------------------------------------------*/
/*--------------------------------------------------------------
* javascriptフォルダの_index.scss
----------------------------------------------------------------*/
/*--------------------------------------------------------------
* projectフォルダの_index.scss
----------------------------------------------------------------*/
.p-mv-content__logo {
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: #ffffff;
  width: 66.6666666667%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  padding: 30px;
}
@media screen and (min-width: 768px) {
  .p-mv-content__logo {
    width: 30%;
  }
}
.p-mv-content__logo-img {
  max-width: 253px;
  width: 68.1818181818%;
  margin-left: 12px;
}
@media screen and (min-width: 768px) {
  .p-mv-content__logo-img {
    max-width: 242px;
    width: 57.619047619%;
    margin-left: 0;
  }
}

@media screen and (min-width: 768px) {
  .p-message-content {
    position: relative;
    z-index: -1;
    padding-bottom: clamp(100px, 20.4285714286vw, 143px);
    margin-right: calc(50% - 50vw);
    padding-top: clamp(200px, 20.4285714286vw, 286px);
    padding-top: 286px;
    background: url("../images/top/img_message-bk.webp") no-repeat center;
    background-size: cover;
  }
}
.p-message-content__textsect {
  font-family: "Hiragino Kaku Gothic ProN", "Noto Sans JP", sans-serif;
  font-weight: 600;
  max-width: 1175px;
}
@media screen and (min-width: 768px) {
  .p-message-content__textsect {
    padding: 0 40px;
    margin-inline: auto;
    color: #ffffff;
  }
}
.p-message-content__textsect > p + P {
  margin-top: 24px;
}
@media screen and (min-width: 768px) {
  .p-message-content__textsect > p + P {
    margin-top: 33px;
  }
}
.p-message-content__heading {
  font-size: 2.25rem;
  font-size: clamp(1.25rem, 2.5714285714vw, 2.25rem);
  line-height: 1.3;
  letter-spacing: 0;
  margin-left: -3px;
}
@media screen and (min-width: 768px) {
  .p-message-content__heading {
    line-height: 1.4444444444;
    margin-left: -3px;
  }
}
.p-message-content__text {
  font-size: 0.875rem;
  line-height: 1.7142857143;
  letter-spacing: 0.04em;
  margin-left: -2px;
  margin-top: 22px;
}
@media screen and (min-width: 768px) {
  .p-message-content__text {
    font-size: 1rem;
    line-height: 2;
    margin-top: 37px;
  }
}
.p-message-content__img-sect {
  position: relative;
  z-index: 0;
  margin-top: 56px;
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
}
@media screen and (min-width: 768px) {
  .p-message-content__img-sect {
    position: static;
    margin-top: 0;
    display: block;
  }
}
.p-message-content__img-sect::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  left: calc(50% - 50vw);
  right: 14.6031746032%;
  background: url("../images/top/img_message-bk-sp.webp") no-repeat center;
  background-size: cover;
}
@media screen and (min-width: 768px) {
  .p-message-content__img-sect::before {
    background: none;
  }
}
.p-message-content__img-title {
  width: 33.8095238095%;
  padding-top: 20px;
  padding-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .p-message-content__img-title {
    display: none;
  }
}
.p-message-content__img {
  width: 57.8412698413%;
  padding-top: 120px;
}
@media screen and (min-width: 768px) {
  .p-message-content__img {
    padding-top: 0;
    position: absolute;
    right: 10.5%;
    top: clamp(100px, 11.1428571429vw, 156px);
    width: clamp(160px, 12.81875vw, 205px);
  }
}

.p-service-content {
  padding-top: 86px;
}
@media screen and (min-width: 768px) {
  .p-service-content {
    padding-top: 174px;
  }
}
.p-service-content__heading {
  font-family: "Hiragino Kaku Gothic ProN", "Noto Sans JP", sans-serif;
  font-weight: 600;
}
.p-service-content__heading-title {
  text-align: center;
  font-size: 1.5rem;
  position: relative;
  padding-bottom: 20px;
  line-height: 1.4166666667;
}
@media screen and (min-width: 768px) {
  .p-service-content__heading-title {
    font-size: 2.25rem;
    padding-bottom: 47px;
  }
}
.p-service-content__heading-title::after {
  content: "";
  display: inline-block;
  width: 218px;
  height: 1px;
  background-color: #707070;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
}
.p-service-content__heading-text {
  text-align: center;
  line-height: 1.7142857143;
  letter-spacing: 0.04em;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .p-service-content__heading-text {
    font-size: 1rem;
    line-height: 2.25;
    margin-top: 39px;
  }
}
.p-service-content__contents {
  margin-top: 46px;
}
@media screen and (min-width: 1025px) {
  .p-service-content__contents {
    margin-top: 84px;
    display: flex;
    flex-direction: row-reverse;
  }
}
.p-service-content__contents-list {
  margin-top: 7px;
}
@media screen and (min-width: 768px) {
  .p-service-content__contents-list {
    margin-top: 20px;
  }
}
@media screen and (min-width: 1025px) {
  .p-service-content__contents-list {
    margin-top: 150px;
    margin-top: clamp(120px, 12.375vw, 198px);
  }
}
@media (any-hover: hover) {
  .p-service-content__contents-item:hover a {
    opacity: 1;
  }
  .p-service-content__contents-item:hover .p-service-content__contents-item-box::after {
    transform: translateX(8px);
  }
}
.p-service-content__contents-item + .p-service-content__contents-item {
  margin-top: 14px;
}
@media screen and (min-width: 768px) {
  .p-service-content__contents-item + .p-service-content__contents-item {
    margin-top: 44px;
  }
}
.p-service-content__contents-item:nth-of-type(1) {
  margin-left: 0;
}
@media screen and (min-width: 1025px) {
  .p-service-content__contents-item:nth-of-type(1) {
    margin-left: -90px;
  }
}
.p-service-content__contents-item:nth-of-type(2), .p-service-content__contents-item:nth-of-type(7) {
  margin-left: 0;
}
@media screen and (min-width: 1025px) {
  .p-service-content__contents-item:nth-of-type(2), .p-service-content__contents-item:nth-of-type(7) {
    margin-left: -60px;
  }
}
.p-service-content__contents-item:nth-of-type(3), .p-service-content__contents-item:nth-of-type(6) {
  margin-left: 0;
}
@media screen and (min-width: 1025px) {
  .p-service-content__contents-item:nth-of-type(3), .p-service-content__contents-item:nth-of-type(6) {
    margin-left: -30px;
  }
}
.p-service-content__contents-item-box {
  font-family: "Hiragino Kaku Gothic ProN", "Noto Sans JP", sans-serif;
  font-weight: 600;
  line-height: 1.8;
  letter-spacing: 0.08em;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  padding-left: 40px;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .p-service-content__contents-item-box {
    padding-left: 0;
  }
}
.p-service-content__contents-item-box::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 13px);
  left: 0;
  width: 26px;
  height: 26px;
  background: url("../images/common/icon-arrow01.svg") no-repeat center;
  background-size: contain;
  transition: 0.4s ease-in-out;
}
@media screen and (min-width: 768px) {
  .p-service-content__contents-item-box::after {
    position: static;
    margin-left: 18px;
    width: 36px;
    height: 36px;
  }
}

.p-company-content__title {
  font-size: 11.25rem;
  font-size: clamp(5.625rem, 12.8571428571vw, 11.25rem);
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  letter-spacing: 0.004em;
  color: rgba(26, 19, 17, 0.06);
  line-height: 1;
  padding-top: 50px;
}
@media screen and (min-width: 768px) {
  .p-company-content__title {
    padding-top: 23px;
    padding-left: 50px;
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  .p-company-content__body {
    margin-left: 38%;
  }
}
.p-company-content__text-sect {
  font-weight: 600;
  letter-spacing: 0.04em;
  line-height: 1.7142857143;
  margin-top: 30px;
}
@media screen and (min-width: 768px) {
  .p-company-content__text-sect {
    line-height: 2.0625;
    font-size: 1rem;
    margin-top: 72px;
  }
}
.p-company-content__text-sect > p + P {
  margin-top: 24px;
}
@media screen and (min-width: 768px) {
  .p-company-content__text-sect > p + P {
    margin-top: 34px;
  }
}
.p-company-content__company {
  margin-top: 50px;
}
@media screen and (min-width: 768px) {
  .p-company-content__company {
    margin-top: 102px;
  }
}
.p-company-content__company-item {
  border-top: 1px solid #d5d5d5;
  padding-top: 20px;
}
@media screen and (min-width: 768px) {
  .p-company-content__company-item {
    padding-top: 0;
    border-top: none;
    display: grid;
    grid-template-columns: 162px 1fr;
    align-items: center;
    max-width: 649px;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 2;
    padding-bottom: 34px;
  }
}
.p-company-content__company-item + .p-company-content__company-item {
  margin-top: 18px;
}
@media screen and (min-width: 768px) {
  .p-company-content__company-item + .p-company-content__company-item {
    margin-top: 28px;
  }
}
.p-company-content__company-item:nth-of-type(2), .p-company-content__company-item:nth-of-type(6) {
  align-items: flex-start;
}
.p-company-content__company-item:nth-last-of-type(1) {
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 22px;
}
@media screen and (min-width: 768px) {
  .p-company-content__company-item:nth-last-of-type(1) {
    border-bottom: none;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .p-company-content__company-item:not(:last-of-type) {
    border-bottom: 1px solid #d5d5d5;
  }
}
.p-company-content__company-item-name {
  font-weight: 600;
  padding-left: 10px;
}
@media screen and (min-width: 768px) {
  .p-company-content__company-item-name {
    padding-left: 24px;
  }
}
.p-company-content__company-item-body {
  font-weight: 300;
  padding-left: 10px;
  line-height: 1.7142857143;
  margin-top: 3px;
}
@media screen and (min-width: 768px) {
  .p-company-content__company-item-body {
    padding-left: 36px;
    margin-top: 0;
  }
}
.p-company-content__company-item-body-item {
  position: relative;
  padding-left: 18px;
  line-height: 1.7142857143;
}
@media screen and (min-width: 768px) {
  .p-company-content__company-item-body-item {
    padding-left: 20px;
  }
}
.p-company-content__company-item-body-item + .p-company-content__company-item-body-item {
  margin-top: -4px;
}
@media screen and (min-width: 768px) {
  .p-company-content__company-item-body-item + .p-company-content__company-item-body-item {
    margin-top: 0;
  }
}
.p-company-content__company-item-body-item::before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #ceced6;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 3px);
  left: 2px;
}

/*--------------------------------------------------------------
* projectフォルダの_index.scss
----------------------------------------------------------------*/
.u-sp-db {
  display: block;
}
@media screen and (min-width: 768px) {
  .u-sp-db {
    display: inline-block;
  }
}

.u-sp-only {
  display: block;
}
@media screen and (min-width: 768px) {
  .u-sp-only {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .u-sp-tel-only {
    pointer-events: none;
  }
}

.u-pc-only {
  display: none;
}
@media screen and (min-width: 768px) {
  .u-pc-only {
    display: block;
  }
}

@media screen and (min-width: 1025px) {
  .u-pc-only-lg {
    display: block;
  }
  .u-pc-only-lg .u-pc-only {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .u-pc-only-lg {
    display: inline;
  }
  .u-pc-only-lg .u-pc-only {
    display: block;
  }
}

.u-small-text-nowrap {
  white-space: nowrap;
  transform: scale(0.8);
}
@media screen and (min-width: 768px) {
  .u-small-text-nowrap {
    transform: scale(1);
  }
}

.u-bg-transparent {
  background-color: transparent !important;
}

.u-text-nowrap {
  white-space: nowrap;
}

/*--------------------------------------------------------------
* utilityフォルダの_index.scss
----------------------------------------------------------------*/
/*--------------------------------------------------------------
* externalフォルダの_index.scss
----------------------------------------------------------------*/
/* =========================================================================================================
	* layout _l
========================================================================================================= */
/* =========================================================================================================
  * component _c
========================================================================================================= */
/* =========================================================================================================
* javascript //jsで操作される設定  _js
========================================================================================================= */
/* =========================================================================================================
	* project _p
========================================================================================================= */
/* =========================================================================================================
	* utility //調整用ファイル _u
========================================================================================================= */
/* =========================================================================================================
	* external //外部ライブラリーの上書き _ex
========================================================================================================= */