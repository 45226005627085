@use "../../global/" as g;

.l-company {
  position: relative;
  padding-bottom: 30px;
  margin-top: 72px;

  @include g.mq(md) {
    margin-top: 0;
  }

  &__bk {
    width: 100%;
    left: 0;
    top: 0;

    @include g.mq(md) {
      position: absolute;
    }
  }

  &__bk01 {
    position: relative;
  }

  &__bk01-img,
  &__bk02-img {
    width: calc(422 / 750 * 100%);
    @include g.mq(md) {
      width: calc(422 / 1600 * 100%);
    }
  }

  &__bk01-img {
    z-index: 1;
  }

  &__bk02-img {
    z-index: -1;
    position: absolute;
    top: 37%;
    left: 20%;
    opacity: 5%;

    @include g.mq(md) {
      left: 9%;
    }
  }
}
