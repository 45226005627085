@use "../../global/" as g;

.p-mv-content {
  &__logo {
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: g.$white;
    // max-width: 480px;
    // width: 100%;
    width: calc(500 / 750 * 100%);
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    padding: 30px;

    @include g.mq(md) {
      width: calc(480 / 1600 * 100%);
    }
  }

  &__logo-img {
    max-width: 253px;
    width: calc(300 / 440 * 100%);
    margin-left: 12px;

    @include g.mq(md) {
      max-width: 242px;
      width: calc(242 / 420 * 100%);
      margin-left: 0;
    }
  }
}
