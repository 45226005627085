@use "../../global/" as g;

.p-message-content {
  @include g.mq(md) {
    position: relative;
    z-index: -1;
    padding-bottom: clamp(100px, calc(100vw / 1400 * 286), 143px);
    margin-right: calc(50% - 50vw);
    padding-top: clamp(200px, calc(100vw / 1400 * 286), 286px);
    padding-top: 286px;
    background: url("../images/top/img_message-bk.webp") no-repeat center;
    background-size: cover;
  }

  &__textsect {
    font-family: g.$Hiragino;
    font-weight: 600;
    max-width: 1175px;

    @include g.mq(md) {
      padding: 0 40px;
      margin-inline: auto;
      color: g.$white;
    }

    > p + P {
      margin-top: 24px;

      @include g.mq(md) {
        margin-top: 33px;
      }
    }
  }

  &__heading {
    @include g.clamp-fz(20, 36, 1400);
    line-height: calc(52 / 40);
    letter-spacing: 0;
    margin-left: -3px;

    @include g.mq(md) {
      line-height: calc(52 / 36);
      margin-left: -3px;
    }
  }

  &__text {
    font-size: g.rem(14);
    line-height: calc(48 / 28);
    letter-spacing: 0.04em;
    margin-left: -2px;
    margin-top: 22px;

    @include g.mq(md) {
      font-size: g.rem(16);
      line-height: calc(32 / 16);
      margin-top: 37px;
    }
  }

  &__img-sect {
    position: relative;
    z-index: 0;
    margin-top: 56px;
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;

    @include g.mq(md) {
      position: static;
      margin-top: 0;
      display: block;
    }

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      inset: 0;
      left: calc(50% - 50vw);
      right: calc(46 / 315 * 100%);
      background: url("../images/top/img_message-bk-sp.webp") no-repeat center;
      background-size: cover;
      @include g.mq(md) {
        background: none;
      }
    }
  }

  &__img-title {
    width: calc(106.5 / 315 * 100%);
    padding-top: 20px;
    padding-bottom: 40px;

    @include g.mq(md) {
      display: none;
    }
  }

  &__img {
    width: calc(182.2 / 315 * 100%);
    padding-top: 120px;
    @include g.mq(md) {
      padding-top: 0;
      position: absolute;
      right: 10.5%;
      top: clamp(100px, calc(100vw / 1400 * 156), 156px);
      width: clamp(160px, calc(100vw / 1600 * 205.1), 205px);
    }
  }
}
