@use "../../global/" as g;
@use "sass:map";

.l-mv {
  position: relative;
  z-index: 0;
  overflow: hidden;

  &__bk {
    @include g.mq(md) {
      height: auto;
    }
  }

  &__bk-img {
    display: block;

    img {
      height: 100svh;
      object-fit: cover;
      object-position: center;

      @include g.mq(md) {
        height: auto;
      }
    }
  }

  &__link-btn {
    position: absolute;
    position: fixed;
    z-index: map.get(g.$layer, contact-btn);
    right: 25px;
    bottom: 30px;

    @include g.mq(md) {
      right: 50px;
    }
  }
}
