@use "../../global/" as g;

.p-company-content {
  &__title {
    @include g.clamp-fz(90, 180, 1400);
    font-family: g.$Oswald;
    font-weight: 400;
    letter-spacing: 0.004em;
    color: rgba($color: g.$black-main, $alpha: 0.06);
    line-height: 1;
    padding-top: 50px;

    @include g.mq(md) {
      padding-top: 23px;
      padding-left: 50px;
      text-align: center;
    }
  }

  &__body {
    @include g.mq(md) {
      margin-left: 38%;
    }
  }

  &__text-sect {
    font-weight: 600;
    letter-spacing: 0.04em;
    line-height: calc(48 / 28);
    margin-top: 30px;

    @include g.mq(md) {
      line-height: calc(33 / 16);
      font-size: g.rem(16);
      margin-top: 72px;
    }

    > p + P {
      margin-top: 24px;

      @include g.mq(md) {
        margin-top: 34px;
      }
    }
  }

  &__company {
    margin-top: 50px;

    @include g.mq(md) {
      margin-top: 102px;
    }
  }

  &__company-item {
    border-top: 1px solid g.$gray;
    padding-top: 20px;

    @include g.mq(md) {
      padding-top: 0;
      border-top: none;
      display: grid;
      grid-template-columns: 162px 1fr;
      align-items: center;
      max-width: 649px;
      font-size: g.rem(16);
      letter-spacing: 0;
      line-height: calc(32 / 16);
      padding-bottom: 34px;
    }

    + .p-company-content__company-item {
      margin-top: 18px;

      @include g.mq(md) {
        margin-top: 28px;
      }
    }

    &:nth-of-type(2),
    &:nth-of-type(6) {
      align-items: flex-start;
    }

    &:nth-last-of-type(1) {
      border-bottom: 1px solid g.$gray;
      padding-bottom: 22px;

      @include g.mq(md) {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    &:not(:last-of-type) {
      @include g.mq(md) {
        border-bottom: 1px solid g.$gray;
      }
    }
  }

  &__company-item-name {
    font-weight: 600;
    padding-left: 10px;

    @include g.mq(md) {
      padding-left: 24px;
    }
  }

  &__company-item-body {
    font-weight: 300;
    padding-left: 10px;
    line-height: calc(48 / 28);
    margin-top: 3px;

    @include g.mq(md) {
      padding-left: 36px;
      margin-top: 0;
    }
  }

  &__company-item-body-item {
    position: relative;
    padding-left: 18px;
    line-height: calc(48 / 28);

    @include g.mq(md) {
      padding-left: 20px;
    }

    + .p-company-content__company-item-body-item {
      margin-top: -4px;

      @include g.mq(md) {
        margin-top: 0;
      }
    }

    &::before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      background-color: #ceced6;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 3px);
      left: 2px;
    }
  }
}
