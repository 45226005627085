@use "../../global/" as g;

.l-service {
  position: relative;
  z-index: 5;

  @include g.mq(md) {
    padding-bottom: 75px;
  }

  @include g.mq(lg) {
    padding-bottom: 450px;
  }

  &__title {
    display: block;
    writing-mode: vertical-lr;
    position: absolute;
    z-index: 2;
    top: -47px;
    left: 0;
    white-space: nowrap;
    font-family: g.$Oswald;
    @include g.clamp-fz(90, 180, 1400);
    letter-spacing: 0.004em;
    // line-height: 0.8;
    line-height: 0.9;
    overflow: hidden;
    color: rgba($color: g.$black-main, $alpha: 0.06);

    @include g.mq(md) {
      top: clamp(-95px, calc(100vw / 1400 * -95), -40px);
      right: 0;
      left: auto;
    }

    @include g.mq(lg) {
      right: clamp(-17px, calc(100vw / 1400 * -17), 10px);
    }

    span {
      color: rgba($color: g.$white, $alpha: 0.3);
      display: inline-block;
    }
  }

  &__bk {
    position: absolute;
    inset: 0;
    height: 100%;
    overflow: hidden;
  }

  &__bk01 {
    width: clamp(calc(123px / 2), calc(100vw / 1600 * 123), 123px);
    aspect-ratio: 1 / 1;
    background-color: rgba($color: g.$red, $alpha: 0.21);
    position: absolute;
    top: 30%;
    left: 12%;
    @include g.mq(md) {
      top: 34%;
      left: 18%;
    }
  }

  &__bk02 {
    width: clamp(calc(187px / 2), calc(100vw / 1600 * 187), 187px);
    aspect-ratio: 1 / 1;
    background-color: rgba($color: g.$blue02, $alpha: 0.08);
    position: absolute;
    top: 44%;
    left: -13%;

    @include g.mq(md) {
      top: 54%;
      left: 4%;
    }
  }

  &__bk03 {
    width: clamp(calc(78px / 2), calc(100vw / 1600 * 78), 78px);
    aspect-ratio: 1 / 1;
    background-color: rgba($color: g.$blue, $alpha: 0.14);
    position: absolute;
    top: 66%;
    left: 8%;

    @include g.mq(md) {
      top: 73.5%;
      left: 38%;
    }
  }

  &__bk04 {
    width: clamp(calc(150px / 2), calc(100vw / 1600 * 150), 150px);
    aspect-ratio: 1 / 1;
    background-color: rgba($color: g.$blue, $alpha: 0.14);
    position: absolute;
    top: 30%;
    left: 74%;

    @include g.mq(md) {
      top: 30%;
      left: 74%;
    }
  }

  &__bk05 {
    width: clamp(calc(79px / 2), calc(100vw / 1600 * 79), 79px);
    aspect-ratio: 1 / 1;
    background-color: rgba($color: g.$red, $alpha: 0.21);
    position: absolute;
    top: 47%;
    left: 92%;

    @include g.mq(md) {
      top: 53%;
      left: 87.5%;
    }
  }

  &__bk06 {
    width: clamp(calc(135px / 2), calc(100vw / 1600 * 135), 135px);
    aspect-ratio: 1 / 1;
    background-color: rgba($color: g.$blue02, $alpha: 0.08);
    position: absolute;
    top: 69%;
    left: 80%;

    @include g.mq(md) {
      top: 69%;
      left: 65%;
    }
  }
}
