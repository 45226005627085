@use "../global/" as g;

.c-page-top-btn {
  position: fixed;
  right: 20px;
  bottom: 80px;
  z-index: 200;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s ease-in-out;
  transform: translateY(200px);

  @include g.mq(md) {
    right: 50px;
    // bottom: 40px;
    bottom: 20px;
  }

  .top & {
    bottom: 140px;

    @include g.mq(md) {
      bottom: 230px;
    }
  }

  &.is-show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  .is-nav-open &,
  .is-lock & {
    visibility: hidden;
    opacity: 0;
    transform: translateY(200px);
  }

  .is-loadding & {
    visibility: hidden;
    opacity: 0;
    transform: translateY(200px);
  }

  &__body {
    position: relative;
    display: block;

    // border: 1px solid g.$white;
    width: 50px;
    height: 50px;
    background-color: g.$blue;
    // background: linear-gradient(-60deg, #0052a4 40%, #256cb2);
    transition: 0.3s ease-in;

    @include g.mq(md) {
      width: 80px;
      height: 80px;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 14px;
      height: 14px;
      content: "";
      background: url("../images/common/icon_arrow-white.svg") no-repeat center;
      background-size: contain;
      transform: translate(-50%, -50%) rotate(90deg);

      @include g.mq(md) {
        width: 30px;
        height: 30px;
      }
    }
  }
}
