@use "../../global/" as g;

.l-message {
  position: relative;
  z-index: 0;
  margin-top: 63px;

  @include g.mq(md) {
    margin-top: clamp(200px, calc(100vw / 1400 * 250), 250px);
    padding-top: clamp(50px, calc(100vw / 1400 * 107), 107px);
  }

  &__bk {
    position: relative;
  }

  &__bk-title {
    max-width: 106.5px;
    @include g.mq(md) {
      position: absolute;
      top: clamp(-106px, calc(100vw / 1450 * -106), -50px);
      left: 8%;
      right: calc(50% - 50vw);
      max-width: 1276px;
    }
  }

  &__bk-title-img {
    display: none;
    @include g.mq(md) {
      display: block;
    }
  }

  &__bk-inner {
    position: absolute;
    right: calc(50% - 50vw);
    z-index: -1;
  }

  &__bk-img {
    display: block;
    height: 100%;

    img {
      object-fit: cover;
      object-position: center;
      aspect-ratio: 1462 / 878;
      height: 100%;
    }
  }
}
