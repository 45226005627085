@use "../global/" as g;

.c-circle-move {
  position: relative;
  //   left: -107px;
  //   right: -107px;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);

  @include g.mq(lg) {
    display: block;
    position: absolute;
    //   left: -100px;
    left: clamp(-100px, calc(100vw / 1600 * -100), 1px);
    overflow: visible;
    margin-left: 0;
    margin-right: 0;
  }

  &__box {
    position: relative;
    z-index: 2;
    display: inline-block;
  }

  &__img01 {
    // position: absolute;
    z-index: -1;
    top: 0;
    width: calc(100vw / 1600 * 1069);
    width: clamp(calc(1069px / 2), calc(100vw / 1600 * 1069), 1069px);
    animation: rotate-anime01 20s linear infinite;

    @include g.mq(lg) {
      width: clamp(calc(1069px / 2), calc(100vw / 1600 * 1069), 1069px);
    }
  }

  &__img02 {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: calc(100vw / 1600 * 842);
    width: clamp(calc(842px / 2), calc(100vw / 1600 * 842), 842px);
    translate: -50% -50%;
    animation: rotate-anime02 18s linear infinite;

    @include g.mq(lg) {
      width: clamp(calc(842px / 2), calc(100vw / 1600 * 842), 842px);
    }
  }

  &__logo-sect {
    position: absolute;
    // width: calc(100vw / 1600 * 348);
    max-width: 260px;
    width: calc(100vw / 375 * 175);
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include g.mq(lg) {
      max-width: 300px;
      top: 51%;
      left: 52%;
    }

    @include g.mq(xl) {
      max-width: 320px;
      top: 52%;
    }

    @include g.mq(xxl) {
      max-width: 348px;
      top: 47%;
    }
  }

  &__logo-sect-sign {
    padding: 0 7px;
    max-width: 250px;

    @include g.mq(lg) {
      max-width: none;
    }
  }

  &__logo-sect-messe {
    font-weight: 600;
    font-size: g.rem(16);
    @include g.clamp-fz(8, 16, 1400);
    line-height: calc(36 / 16);
    letter-spacing: 0.04em;
    text-align: center;
  }

  &__company-logo {
    position: relative;
    width: calc(100vw / 1600 * 168.82);
    min-width: 84.42px;
    max-width: 168.82px;
    margin-top: clamp(17px, calc(100vw / 1600 * 40), 40px);

    .dots {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: g.$black-main;
      position: absolute;
      top: calc(42% - 5px);
      left: calc(42% - 5px);

      span {
        position: relative;
        &::after {
          content: "";
          display: inline-block;
          width: 1px;
          height: 150px;
          min-height: 80px;
          height: calc(100vw / 1600 * 160);
          max-height: 160px;
          background-color: g.$black-main;
          position: absolute;
          right: 7px;
          rotate: 18deg;

          @include g.mq(md) {
            height: calc(100vw / 1600 * 160);
            max-height: 160px;
          }

          @include g.mq(xl) {
            right: 15px;
          }

          @include g.mq(xxl) {
            right: 19px;
          }
        }
      }
    }
  }

  &__company-logo-text {
    font-weight: 600;
    text-align: center;
    @include g.clamp-fz(12, 16, 1400);
    line-height: calc(40 / 24);
    margin-top: 40px;
    margin-top: clamp(30px, calc(100vw / 1600 * 40), 40px);
  }
}

@keyframes rotate-anime01 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-anime02 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
