@use "../global/" as g;
@use "sass:map";

.c-modal {
  position: relative;

  &__bg {
    position: absolute;
    // z-index: map.get(g.$layer, modal-bg);
    z-index: -1;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    background-color: rgba($color: g.$white, $alpha: 0.94);
    opacity: 0; /* 初期状態で非表示 */
    visibility: hidden; /* 初期状態で非表示 */
    transition: opacity 0.3s ease, visibility 0.3s ease;

    .is-modal-active & {
      opacity: 1; /* 非表示を解除 */
      visibility: visible; /* 非表示を解除 */
    }
  }

  &__inner {
    position: fixed;
    z-index: map.get(g.$layer, modal-bg);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    // padding: 0 30px;
    opacity: 0; /* 初期状態で非表示 */
    visibility: hidden; /* 初期状態で非表示 */
    transition: opacity 0.3s ease, visibility 0.3s ease;

    .is-modal-active & {
      opacity: 1; /* 非表示を解除 */
      visibility: visible; /* 非表示を解除 */
    }

    @include g.mq(md) {
      // padding: 0 40px;
    }

    @include g.mq(lg) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__content {
    max-width: 1380px;
    width: 100%;
  }

  &__content-wrpper {
    position: relative;
    width: 100%;
    margin-inline: auto;
    overflow: auto;
    height: 100vh;
    padding: 55px 30px;

    @include g.mq(lg) {
      display: flex;
      align-items: center;
      padding: 0 40px;
    }
  }

  &__img-sect {
    position: relative;
    width: 100%;

    @include g.mq(lg) {
      padding-right: 80px;
    }
  }

  &__img {
    max-width: 1017px;
  }

  &__card-close {
    position: absolute;
    z-index: 10;
    right: 0;
    bottom: calc(100% + 11px);

    @include g.mq(lg) {
      top: 0;
      bottom: auto;
    }
  }

  &__card-closeBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: g.$Oswald;
    font-weight: 400;
    text-transform: uppercase;

    @include g.mq(md) {
      gap: 12px;
      font-size: g.rem(16);
    }
    span {
      display: inline-block;
      width: 20px;
      height: 20px;
      position: relative;
      cursor: pointer;

      @include g.mq(md) {
        width: 40px;
        height: 40px;
      }

      &::before,
      &::after {
        content: "";
        display: inline-block;
        width: 15px;
        height: 1px;
        border-radius: 4px;
        background-color: #707070;
        position: absolute;
        left: calc(50% - 6px);
        top: calc(50% - 1px);
        rotate: 45deg;

        @include g.mq(md) {
          width: 30px;
        }
      }

      &::after {
        rotate: -45deg;
      }
    }
  }

  &__content-body {
    width: 100%;
    margin-top: 20px;

    @include g.mq(lg) {
      background-color: rgba($color: g.$white, $alpha: 0.92);
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      max-width: 779px;
      max-width: 779px;
      width: 70%;
      padding-top: 55px;
      padding-bottom: 55px;
      padding-left: 87px;
      padding-right: 40px;
      margin-top: 0;
    }

    @include g.mq(lg) {
      width: 100%;
    }
  }

  &__content-body-title {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 7px;

    @include g.mq(md) {
      padding-bottom: 20px;
    }

    &::after {
      content: "";
      background-color: #707070;
      display: inline-block;
      height: 1px;
      max-width: 100px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;

      @include g.mq(md) {
        max-width: 180px;
      }
    }
  }

  &__content-body-title-en {
    font-family: g.$Oswald;
    @include g.clamp-fz(36, 60, 1600);
    letter-spacing: 0.004em;
    line-height: calc(80 / 60);
    font-weight: 400;
  }

  &__content-body-title-ja {
    font-weight: 600;
    letter-spacing: 0.08em;
    line-height: calc(36 / 16);
    font-size: g.rem(10);
    @include g.mq(md) {
      font-size: g.rem(16);
    }
  }

  &__content-body-text-sect {
    margin-top: 20px;

    @include g.mq(md) {
      margin-top: 40px;
    }
  }

  &__content-body-text-lead {
    @include g.clamp-fz(18, 32, 1600);
    font-weight: 600;
    line-height: calc(60 / 36);

    @include g.mq(md) {
      line-height: calc(48 / 32);
    }
  }

  &__content-body-text {
    font-weight: 600;
    letter-spacing: 0.04em;
    line-height: calc(40 / 28);
    margin-top: 17px;

    @include g.mq(md) {
      font-size: g.rem(16);
      margin-top: 20px;
      line-height: calc(33 / 16);
    }
  }
}
