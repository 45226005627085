@use "../global/" as g;

.c-mv-link-btn {
  background-color: g.$blue;
  position: relative;
  border-radius: 50%;
  color: g.$white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;

  @include g.mq(md) {
    max-width: 180px;
    max-height: 180px;
    width: calc(100vw / 1400 * 180);
    height: calc(100vw / 1400 * 180);
  }

  &::after {
    content: "";
    max-width: 91px;
    max-height: 91px;
    width: 91px;
    height: 91px;
    border: 1px solid g.$white;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include g.mq(md) {
      max-width: 168px;
      max-height: 168px;
      width: calc(100vw / 1400 * 168);
      height: calc(100vw / 1400 * 168);
    }
  }

  &__img {
    position: relative;
    top: 4px;

    svg {
      max-width: 28px;

      @include g.mq(md) {
        max-width: 57px;
        width: calc(100vw / 57 * 1400);
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -14px;

    @include g.mq(md) {
      top: -8px;
    }
  }

  &__title-en {
    font-family: g.$Oswald;
    font-weight: 400;
    letter-spacing: 0.04em;
    font-size: g.rem(22);

    @include g.mq(md) {
      font-size: g.rem(36);
      @include g.clamp-fz(22, 36, 1400);
    }
  }

  &__title-ja {
    font-weight: 600;
    letter-spacing: 0.12em;
    font-size: g.rem(10);
    margin-top: -13px;

    @include g.mq(md) {
      font-size: g.rem(12);
      @include g.clamp-fz(10, 12, 1400);
    }
  }
}
