@use "../../global/" as g;

.p-service-content {
  padding-top: 86px;

  @include g.mq(md) {
    padding-top: 174px;
  }

  &__heading {
    font-family: g.$Hiragino;
    font-weight: 600;
  }
  &__heading-title {
    text-align: center;
    font-size: g.rem(24);
    position: relative;
    padding-bottom: 20px;
    line-height: calc(68 / 48);

    @include g.mq(md) {
      font-size: g.rem(36);
      padding-bottom: 47px;
    }

    &::after {
      content: "";
      display: inline-block;
      width: 218px;
      height: 1px;
      background-color: g.$border;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-inline: auto;
    }
  }

  &__heading-text {
    text-align: center;
    line-height: calc(48 / 28);
    letter-spacing: 0.04em;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    @include g.mq(md) {
      font-size: g.rem(16);
      line-height: calc(36 / 16);
      margin-top: 39px;
    }
  }

  &__contents {
    margin-top: 46px;

    @include g.mq(lg) {
      margin-top: 84px;
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &__contents-list {
    margin-top: 7px;

    @include g.mq(md) {
      margin-top: 20px;
    }

    @include g.mq(lg) {
      margin-top: 150px;
      margin-top: clamp(120px, calc(100vw / 1600 * 198), 198px);
    }
  }

  &__contents-item {
    // pointer-events: none;
    @include g.any-hover {
      a {
        opacity: 1;
      }

      .p-service-content__contents-item-box {
        &::after {
          transform: translateX(8px);
        }
      }
    }
    + .p-service-content__contents-item {
      margin-top: 14px;

      @include g.mq(md) {
        margin-top: 44px;
      }
    }

    &:nth-of-type(1) {
      margin-left: 0;

      @include g.mq(lg) {
        margin-left: -90px;
      }
    }

    &:nth-of-type(2),
    &:nth-of-type(7) {
      margin-left: 0;

      @include g.mq(lg) {
        margin-left: -60px;
      }
    }

    &:nth-of-type(3),
    &:nth-of-type(6) {
      margin-left: 0;

      @include g.mq(lg) {
        margin-left: -30px;
      }
    }
  }

  &__contents-item-box {
    font-family: g.$Hiragino;
    font-weight: 600;
    line-height: calc(36 / 20);
    letter-spacing: 0.08em;
    position: relative;
    display: flex;
    align-items: center;
    font-size: g.rem(20);
    padding-left: 40px;
    cursor: pointer;

    @include g.mq(md) {
      padding-left: 0;
    }

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: calc(50% - 13px);
      left: 0;
      width: 26px;
      height: 26px;
      background: url("../images/common/icon-arrow01.svg") no-repeat center;
      background-size: contain;
      transition: 0.4s ease-in-out;

      @include g.mq(md) {
        position: static;
        margin-left: 18px;
        width: 36px;
        height: 36px;
      }
    }
  }
}
